<template>
  <!-- LISTS -->

  <h2>Base list</h2>

  <Pagination />
  <Search
    v-model="search"
    searchEndpoint="utilisateur"
    searchPlaceholder="Rechercher un utilisateur"
  >
    <template v-slot:suggestions="{ item }">
      <router-link :to="{ name: 'user', params: { userid: item.id } }">
        {{ item.prenom }}
        {{ item.nom }}
      </router-link>
    </template>

    <SelectExtended
      id="entite"
      v-model="search.filters.entite.values"
      multiple
      search
      label="Entité"
      optionKey="id"
      optionValue="nom"
      apiEndpoint="entite"
    />

    <SelectExtended
      id="profil"
      v-model="search.filters.profil.values"
      multiple
      label="Profil"
      optionKey="id"
      optionValue="designation"
      apiEndpoint="profil"
    />

    <Radio
      v-model="search.filters.actif.values"
      id="actif"
      :items="[
        { label: 'oui', value: true },
        { label: 'non', value: false },
      ]"
      label="Actif"
      inputStyle="inline"
    />
  </Search>
  <List
    :api="list.api"
    :header="list.headers"
    :items="list.col"
    :item-key="list.key"
    :target="list.target"
    @selectedItems="selectItems"
  >
    <template v-slot:prenom="{ item }">
      <UserID
        :userNameFirstName="item.prenom"
        :userNameLastName="item.nom"
        :userAvatarImage="item.avatar"
        userAvatarSize="small"
        :userAvatarColor="item.couleur"
        :userDisabled="!item.actif"
      />
    </template>

    <template v-slot:profils="{ item }">
      <MiniTag
        v-if="item.profils"
        :text="item.profils.map((profil) => profil.designation).join(', ')"
        color="primary"
      />
    </template>

    <template v-slot:actions>
      <!-- <Btn icon="kefta-dots" default round grow/> -->
      <ToolDropdown>
        <MenuItem
          :item-data="{
            label: 'Supprimer',
            iconName: 'trash-alt',
          }"
          @click="modalDeleteUser = true"
        />
        <MenuItem
          :item-data="{
            label: 'Désactiver',
            iconName: 'user-times',
          }"
        />
        <MenuItem
          :item-data="{
            label: 'Ré-initialiser le mot de passe',
            iconName: 'key',
          }"
          @click="modalReinitUserPwd = true"
        />
        <MenuItem
          :item-data="{
            label: 'Voir les logs',
            route: { name: 'login' },
            iconName: 'clipboard-list',
          }"
        />
        <MenuItem
          :item-data="{
            label: 'Droits',
            route: { name: 'login' },
            iconName: 'lock',
          }"
        />
      </ToolDropdown>
      <Btn icon="create" default round grow />
    </template>

    <!-- Footer -->
    <template v-slot:footer>
      <p>Goodbye!</p>
    </template>
  </List>

  <hr>

  <!-- DRAGGABLE LIST -->
  <h2>Draggable list</h2>

  <List
    :api="list.api"
    :header="list.headers"
    :items="list.col"
    :item-key="list.key"
    :target="list.target"
    @selectedItems="selectItems"
    isDraggable
    @list-drag-change="changeData($event)"
  >
    <template v-slot:prenom="{ item }">
      <UserID
        :userNameFirstName="item.prenom"
        :userNameLastName="item.nom"
        :userAvatarImage="item.avatar"
        userAvatarSize="small"
        :userAvatarColor="item.couleur"
        :userDisabled="!item.actif"
      />
    </template>

    <template v-slot:profils="{ item }">
      <MiniTag
        v-if="item.profils"
        :text="item.profils.map((profil) => profil.designation).join(', ')"
        color="primary"
      />
    </template>

    <template v-slot:actions>
      <!-- <Btn icon="kefta-dots" default round grow/> -->
      <ToolDropdown>
        <MenuItem
          :item-data="{
            label: 'Supprimer',
            iconName: 'trash-alt',
          }"
          @click="modalDeleteUser = true"
        />
        <MenuItem
          :item-data="{
            label: 'Désactiver',
            iconName: 'user-times',
          }"
        />
        <MenuItem
          :item-data="{
            label: 'Ré-initialiser le mot de passe',
            iconName: 'key',
          }"
          @click="modalReinitUserPwd = true"
        />
        <MenuItem
          :item-data="{
            label: 'Voir les logs',
            route: { name: 'login' },
            iconName: 'clipboard-list',
          }"
        />
        <MenuItem
          :item-data="{
            label: 'Droits',
            route: { name: 'login' },
            iconName: 'lock',
          }"
        />
      </ToolDropdown>
      <Btn icon="create" default round grow />
    </template>

    <!-- Footer -->
    <template v-slot:footer>
      <p>Goodbye!</p>
    </template>
  </List>

  <pre><code>{{ JSON.stringify(selectedItems, null, 2) }}</code></pre>
</template>

<script>
import UserID from '@/components/user/UserID.vue'
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import Pagination from '@/components/list/Pagination.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import Search from '@/components/list/Search.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Radio from '@/components/form/Radio.vue'

export default {
  name: 'StyleguideListsView',

  components: {
    ToolDropdown,
    MenuItem,
    List,
    Search,
    Pagination,
    MiniTag,
    Btn,
    UserID,
    SelectExtended,
    Radio,
  },

  data() {
    return {
      search: {
        attributs: ['nom', 'prenom', 'email'],
        filters: {
          entite: {
            type: 'object',
            key: 'id',
            values: [],
          },
          profil: {
            type: 'object',
            key: 'uid',
            values: [],
          },
          actif: {
            type: 'boolean',
            key: null,
            values: null,
            label: 'Actif',
          },
        },
      },
      list: {
        target: {
          route: {
            name: 'user',
            paramsFromRoute: undefined,
            itemParams: { userid: 'id' },
          },
        },
        api: {
          endpoint: 'utilisateur',
          params: {
            sort: 'prenom.ASC,nom.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Prénom,Nom', dataField: 'prenom,nom' },
          {
            label: 'Mail',
            dataField: 'email',
            hidden: 'mobile' /* , grow: true */,
            isLink: true,
          },
          { label: 'Profils de droits', hidden: 'tablet' },
        ],
        col: ['prenom', 'email', 'profils'],

        key: 'id',
        meta: {},
      },
      selectedItems: [],
    }
  },

  methods: {
    selectItems(event) {
      this.selectedItems = event.selected
    },
    changeData(datas) {
      this.list.datas = datas
      console.log(this.list.datas)
    },
  },
}
</script>
